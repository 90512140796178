import React from 'react';
import Counter from '../../../components/counter';
import { useCart } from '../../../core/hooks';
import { CartItem } from '../../../models/order-models';

export const CartCounter = (props: { cartItem: CartItem }) => {
  const item = props.cartItem;
  const { updateQuantity } = useCart();
  return (
    <Counter
      isLoading={item.isLoading}
      value={item?.orderItemQuantity!}
      onChange={(count) => {
        updateQuantity(item, count);
      }}
      isProductAddedToCart={true}
      className="numberField"
      updateValue={false}
      defaultValue={item.orderItemQuantity}
      disabled={false}
    />
  );
};
