import React, { useEffect } from 'react';
import { toastr } from 'react-redux-toastr';
import { useDataLoader, usePaginatedDataLoader } from 'react-remote-data-hooks';
import api from '../../../api';
import {
  ListFilters,
  MultiSelectFilter,
  SingleSelectFilter,
} from '../../../components/list-filters';
import { FilterValueType } from '../../../components/list-filters/saved-filters';
import StaffMultiSelectFilter from '../../../components/list-filters/StaffMultiSelectFilter';
import { ApiResponse, SupervisorFilter } from '../../../core/api/models';
import { useBrandsAndBranches } from '../../../core/hooks/useBrandsAndBranches';
import { useOrder } from '../../../core/hooks/useOrder';
import { Dict } from '../../../models';
import { Branch } from '../../../models/brand';
import { translate } from '../../../utils/text-utils';

export const OrderFilterComponent = (props: {
  filter: Dict<FilterValueType>;
  onFilterChange: (filter: Dict<FilterValueType>) => void;
  filterTypes: FilterTypes[];
  prefixKey?: string;
}) => {
  const { orderState } = useOrder(api.order);
  // const { loadFilterData } = useOrder(api.order);
  useEffect(() => {
    // this call changed to useIntiate hook to avoid repeated call every time
    // loadFilterData();
  }, []);
  const { brandState } = useBrandsAndBranches();
  const brandID = (props?.filter?.brand as any)?.value;
  const getBranches = (): Branch[] => {
    if (brandID) {
      return (
        brandState?.brandAdBranch?.find((p) => p.brandID === brandID)
          ?.branches ?? []
      );
    }
    return [];
  };
  const filterTypes = props.filterTypes;
  const includeFilter = (key: FilterTypes) => {
    return filterTypes?.indexOf(key) !== -1;
  };



  return (
    <ListFilters
      // key="filter-row"
      // savedFilters={reportSettings?.filters || []}
      onSaveFilter={() => {
        toastr.message('Message', 'Not Implemented');
      }}
      isSavingFilter={false}
      onRemoveFilter={() => {
        toastr.message('Message', 'Not Implemented');
      }}
      filters={props.filter}
      onFilter={(searchFilters: Dict<FilterValueType>) => {
        if (searchFilters?.brand !== props.filter?.brand) {
          if (searchFilters.branch) {
            delete searchFilters.branch;
          }
        }
        props.onFilterChange(searchFilters);
      }}
    >
      {includeFilter(FilterTypes.requestedBy) && (
        <StaffMultiSelectFilter
          label={translate('orders.requestedBy')}
          filterKey="requestedBy"
          key="requestedBy"
          allowSearch
          data={orderState?.filterData?.requestedByList ?? []}
          showRole
        />
      )}
      {includeFilter(FilterTypes.approvedBy) && (
        <StaffMultiSelectFilter
          label={translate('orders.approvedBy')}
          filterKey="approvedBy"
          key="approvedBy"
          allowSearch
          data={orderState?.filterData?.approvalUsers ?? []}
          showRole
        />
      )}
      {includeFilter(FilterTypes.rejectedBy) && (
        <StaffMultiSelectFilter
          label={translate('orders.rejectedBy')}
          filterKey="rejectedBy"
          key="rejectedBy"
          allowSearch
          data={orderState?.filterData?.approvalUsers ?? []}
          showRole
        />
      )}
      {includeFilter(FilterTypes.cancelledBy) && (
        <StaffMultiSelectFilter
          label={translate('orders.cancelledBy')}
          filterKey="cancelledBy"
          key="cancelledBy"
          allowSearch
          data={orderState?.filterData?.approvalUsers ?? []}
          showRole
        />
      )}
      {includeFilter(FilterTypes.createdFor) && (
        <StaffMultiSelectFilter
          label={translate('orders.createdFor')}
          filterKey="createdFor"
          key="createdFor"
          allowSearch
          data={
            orderState?.filterData?.requestedByList?.filter(
              (user) => user.role === 'User'
            ) ?? []
          }
        // showRole
        />
      )}
      {includeFilter(FilterTypes.salesPerson) && (
        <StaffMultiSelectFilter
          label={translate('salesPerson.salesPerson')}
          filterKey="salesperson"
          key="salesperson"
          allowSearch
          data={
            orderState?.filterData?.approvalUsers?.filter(
              (user) => user.role === 'Salesperson'
            ) ?? []
          }
        />
      )}
      {includeFilter(FilterTypes.paymentMethod) && (
        <MultiSelectFilter
          filterKey={'paymentID'}
          label={translate('orders.paymentMethod')}
          labelField="name"
          valueField="id"
          // value={[]}
          options={(orderState?.filterData?.paymentMethods as any) ?? []}
        />
      )}
      {includeFilter(FilterTypes.paymentStatus) && (
        <SingleSelectFilter
          options={[{ status: 'Pending' }, { status: 'Received' }]}
          label={translate('orders.paymentStatus')}
          filterKey="paymentStatus"
          key="paymentStatus"
          allowSearch
          // options={brands}
          valueField="status"
          labelField="status"
        />
      )}
      {includeFilter(FilterTypes.brand) && (
        <SingleSelectFilter
          options={orderState?.filterData?.brandList ?? []}
          label={translate('common.brand')}
          filterKey="brand"
          key="brand"
          allowSearch
          // options={brands}
          valueField="brandID"
          labelField="brandName"
          keyPrefix={props.prefixKey}
        />
      )}
      {includeFilter(FilterTypes.branch) && (
        <MultiSelectFilter
          label={translate('common.branch')}
          filterKey="branch"
          key="branch"
          allowSearch
          options={getBranches()}
          valueField="warehouseID"
          labelField="warehouseName"
        //  loadData={() => api.user.getBrandsForFilter()}
        />
      )}
      {includeFilter(FilterTypes.multiBrand) && (
        <MultiSelectFilter
          label={translate('common.brand')}
          filterKey="brand"
          key="multibrand"
          allowSearch
          options={brandState?.brandAdBranch ?? []}
          valueField="brandID"
          labelField="brandName"
          //  loadData={() => api.user.getBrandsForFilter()}
          keyPrefix={props.prefixKey}
        />
      )}
      {includeFilter(FilterTypes.ageing) && (
        <SingleSelectFilter
          options={[
            { label: '3', value: '3' },
            { label: '4', value: '4' },
            { label: '5', value: '5' },
            { label: '6', value: '6' },
            { label: '7', value: '7' },
            { label: '8', value: '8' },
            { label: '10+', value: '10' },
          ]}
          label="Ageing"
          filterKey="escDays"
          key="escDays"
          allowSearch
          valueField="value"
          labelField="label"
          keyPrefix={props.prefixKey}
        />
      )}
      {includeFilter(FilterTypes.escalationType) && (
        <SingleSelectFilter
          options={[
            { label: 'Payment Due', value: 'Payment Due' },
            { label: 'Credit Request', value: 'Credit Request' },
            { label: 'Credit Extension Request', value: 'Credit Extension Request' },
            { label: 'Order Approved', value: 'Order Approved' },
            { label: 'Inventory Reserved', value: 'Inventory Reserved' },
            { label: 'Inventory Expired', value: 'Inventory Expired' },
            { label: 'Customer Approval', value: 'Customer Approval' },
          ]}
          label="Escalation Type"
          filterKey="escType"
          key="escType"
          allowSearch
          valueField="value"
          labelField="label"
          keyPrefix={props.prefixKey}
        />
      )}
      {includeFilter(FilterTypes.supervisor) && (
        <SingleSelectFilter
          label="Supervisor"
          filterKey="supervisor"
          key="supervisor"
          allowSearch
          options={(orderState?.filterData?.superviserList as any) ?? []}
          valueField="userID"
          labelField="userFullName"
        />
      )}
      {includeFilter(FilterTypes.userGroup) && (
        <SingleSelectFilter
          label="User Group"
          filterKey="userGroupID"
          key="userGroupID"
          allowSearch
          options={(orderState?.filterData?.userGroup as any) ?? []}
          valueField="userGroupID"
          labelField="userGroupName"
        />
      )}
      {includeFilter(FilterTypes.salesMan) && (
        <SingleSelectFilter
          label={translate('salesPerson.salesPerson')}
          filterKey="salesPerson"
          key="salesPerson"
          allowSearch
          options={(orderState?.filterData?.salesPersonList as any) ?? []}
          valueField="userID"
          labelField="userFullName"
        />
      )}
    </ListFilters>
  );
};

export enum FilterTypes {
  requestedBy = 'requestedBy',
  approvedBy = 'approvedBy',
  rejectedBy = 'rejectedBy',
  createdFor = 'createdFor',
  cancelledBy = 'cancelledBy',
  paymentMethod = 'paymentID',
  paymentStatus = 'paymentStatus',
  salesPerson = 'salesperson',
  brand = 'brand',
  branch = 'branch',
  multiBrand = 'multiBrand',
  ageing = 'escDays',
  escalationType = 'escType',
  supervisor = 'supervisor',
  userGroup = 'userGroupID',
  salesMan = 'salesPerson'
}