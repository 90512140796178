import { Dict } from '../../models';
import { User } from './user';

export interface TokenData {
  otpExpireTime: any;
  token: string;
  userId: string;
  userType: UserType;
  expiryDate: Date;
  apiPermissions: Dict;
  roles: string[];
  user: User;
  userToken: string;
  passwordExists: string;
  verified: boolean;
  userData?: any;
}

export enum UserType {
  Salesperson = 'Salesperson',
  Customer = 'Customer',
  User = 'User',
}

export interface Roles {
  add_address: boolean;
  add_brand: boolean;
  add_contact_person: boolean;
  add_customer: boolean;
  add_document: boolean;
  add_staff: boolean;
  bulk_estimate: boolean;
  bulk_order: boolean;
  create_customer_request: boolean;
  create_order_request: boolean;
  create_quotation_request: boolean;
  create_schedule_master: boolean;
  create_schedule_list: boolean;
  customer_approve: boolean;
  customer_orders: boolean;
  customer_request_view: boolean;
  customer_review: boolean;
  customer_view: boolean;
  customers_list: boolean;
  edit_address: boolean;
  edit_brand: boolean;
  edit_company_profile: boolean;
  edit_contact_person: boolean;
  edit_customer: boolean;
  edit_document: boolean;
  edit_personal_info: boolean;
  edit_quotation_request: boolean;
  edit_staff_address: boolean;
  edit_staff_permission: boolean;
  edit_staff_personalinfo: boolean;
  export_customer_orders: boolean;
  export_customer_request: boolean;
  export_quotation: boolean;
  export_return: boolean;
  graph_view: boolean;
  high_order_request: boolean;
  import_customer: boolean;
  list_address: boolean;
  list_brands: boolean;
  list_contact_person: boolean;
  list_documents: boolean;
  order_proceed_checkout: boolean;
  order_request_again: boolean;
  order_view: boolean;
  place_order_for_customers: boolean;
  quotation_list: boolean;
  quotation_view: boolean;
  return_list: boolean;
  return_view: boolean;
  return_reject: boolean;
  return_approve: boolean;
  report: boolean;
  report_sales: boolean;
  report_payment: boolean;
  report_salesperson: boolean;
  report_customer: boolean;
  schedule_master_list: boolean;
  schedule_list: boolean;
  staff_list: boolean;
  todo_list: boolean;
  user_log: boolean;
  view_account_general_info: boolean;
  view_account_personal_info: boolean;
  view_company_profile: boolean;
  view_staff_address: boolean;
  view_staff_log: boolean;
  view_staff_permission: boolean;
  view_staff_personalinfo: boolean;
}

export enum UserPermissionTypes {
  delete_staff = 'delete_staff',
  list_creditrequest = 'list_creditrequest',
  process_duepayments = 'process_duepayments',
  list_paymenthistory = 'list_paymenthistory',
  view_duepayments = 'view_duepayments',
  add_address = 'add_address',
  add_brand = 'add_brand',
  add_contact_person = 'add_contact_person',
  add_customer = 'add_customer',
  add_document = 'add_document',
  add_staff = 'add_staff',

  bulk_estimate = 'bulk_estimate',
  bulk_order = 'bulk_order',

  create_customer_request = 'create_customer_request',
  create_order_request = 'create_order_request',
  create_quotation_request = 'create_quotation_request',
  customer_approve = 'customer_approve',
  customer_orders = 'customer_orders',
  customer_request_view = 'customer_request_view',
  customer_review = 'customer_review',
  customer_view = 'customer_view',
  customers_list = 'customers_list',

  edit_address = 'edit_address',
  edit_brand = 'edit_brand',
  edit_company_profile = 'edit_company_profile',
  edit_contact_person = 'edit_contact_person',
  edit_customer = 'edit_customer',
  edit_document = 'edit_document',
  edit_personal_info = 'edit_personal_info',
  edit_quotation_request = 'edit_quotation_request',
  edit_staff_address = 'edit_staff_address',
  edit_staff_permission = 'edit_staff_permission',
  edit_staff_personalinfo = 'edit_staff_personalinfo',
  export_customer_orders = 'export_customer_orders',
  export_customer_request = 'export_customer_request',
  export_quotation = 'export_quotation',
  export_return = 'export_return',

  graph_view = 'graph_view',

  high_order_request = 'high_order_request',

  import_customer = 'import_customer',

  list_address = 'list_address',
  list_brands = 'list_brands',
  list_contact_person = 'list_contact_person',
  list_documents = 'list_documents',

  order_proceed_checkout = 'order_proceed_checkout',
  order_request_again = 'order_request_again',
  order_view = 'order_view',

  place_order_for_customers = 'place_order_for_customers',

  quotation_list = 'quotation_list',
  quotation_view = 'quotation_view',

  return_list = 'return_list',
  return_view = 'return_view',
  return_reject = 'return_reject',
  return_approve = 'return_approve',

  report = 'report',
  report_sales = 'report_sales',
  report_payment = 'report_payment',
  report_customer = 'report_customer',
  report_salesperson = 'report_salesperson',

  staff_list = 'staff_list',

  todo_list = 'todo_list',

  user_log = 'user_log',

  view_account_general_info = 'view_account_general_info',
  view_account_personal_info = 'view_account_personal_info',
  view_company_profile = 'view_company_profile',
  view_staff_address = 'view_staff_address',
  view_staff_log = 'view_staff_log',
  view_staff_permission = 'view_staff_permission',
  view_staff_personalinfo = 'view_staff_personalinfo',

  credit_approve = 'credit_approve',
  reject_creditrequest = 'reject_creditrequest',

  list_salespersons = 'list_salespersons',
  create_salesperson = 'create_salesperson',
  edit_salesperson = 'edit_salesperson',
  delete_salesperson = 'delete_salesperson',

  schedule_master_list = 'schedule_master_list',
  create_schedule_master = 'create_schedule_master',
  schedule_list = 'schedule_list',
  create_schedule_list = 'create_schedule_list',

  report_salesperson_visit = 'report_salesperson_visit',
  report_salesperson_yearperformance = 'report_salesperson_yearperformance',
  report_salesperson_salesactivity = 'report_salesperson_salesactivity',
  report_salesperson_customeractivity = 'report_salesperson_customeractivity',
  report_salesperson_escalation = 'report_salesperson_escalation',
  report_salesperson_usage = 'report_salesperson_usage',
}
