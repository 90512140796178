import React, { useEffect, useState } from 'react';
import { useDataLoader } from 'react-remote-data-hooks';
import { DropdownList } from 'react-widgets';
import api from '../../api';
import Button from '../../components/button';
import { ISearchParams, Model, ProductFilter } from '../../core/api/models';
import './search-box.scss';
import 'react-widgets/dist/css/react-widgets.css';
import classnames from 'classnames';
import { useProduct } from '../../core/hooks';
import { useBrandsAndBranches } from '../../core/hooks/useBrandsAndBranches';
import { translate } from '../../utils/text-utils';
import { ITabDataWithColumns } from '../orders/order-listing-screen';
import ProductFilterPopUp from './ProductFilterPopUp';
import { useHistory, useLocation, useParams } from 'react-router-dom';

interface SearchTabProps {
  setIsSearched?: (data: boolean) => void;
  urlProps?: UrlProps;
}
export enum SearchTabNames {
  Model = 'Model',
  ProductPartNumber = 'ProductOrPartNumber',
  VINNumber = 'VinNumber',
}
export interface UrlProps {
  tab: SearchTabNames;
  model?: string;
  vin?: string;
  partNo?: string;
}

const TabSearch: React.FunctionComponent<SearchTabProps> = (props) => {
  const { hash } = useLocation();
  const searchKey = hash?.replace('#', '');
  const urlData = props?.urlProps;
  // const split = searchKey?.split('-');
  // const searchTabStringFromUrl = split?.[0];
  // const defaultSearchValueFromUrl = split?.[1];

  const {
    searchParams,
    selectedFilter,
    isProductLoading,
    setSearchParam,
    setProductFilter,
  } = useProduct();
  const { brandState } = useBrandsAndBranches();
  const [filterOpen, setFilterOpen] = useState(!!searchParams?.model);
  const [brand, setBrand] = useState<string>();

  useEffect(() => {
    setBrand(brandState.selectedBrand?.brandID ?? '');
  }, [brandState.selectedBrand]);

  const {
    data: modelsList,
    loading,
    loaded,
    reload,
  } = useDataLoader<Model[]>(() =>
    api.model.getModelsV2(brandState?.selectedBrand?.brandID ?? '')
  );

  React.useEffect(() => {
    if (loaded) {
      reload(brand);
    }
  }, [brandState?.selectedBrand?.brandID]);

  const tabs: ITabDataWithColumns[] = [
    {
      tabTitle: translate('Home.Model'),
      tabKey: SearchTabNames.Model,
    },
    {
      tabTitle: translate('Home.Product_or_part_Number'),
      tabKey: SearchTabNames.ProductPartNumber,
    },
    {
      tabTitle: translate('Home.VIN_Number'),
      tabKey: SearchTabNames.VINNumber,
    },
  ];

  // const selectedTabFromUrl = tabs.find((p) => p.tabKey === urlData?.tab);
  const [selectedTab, setSelectedTab] = React.useState<ITabDataWithColumns>(
    tabs[0]
  );

  const [model, setModel] = useState<Model>();
  const [vin, setVin] = useState('');
  const [pNumber, setPNumber] = useState('');

  const [isDisabled, setIsDisabled] = useState(true);
  const history = useHistory();

  /* Update local state and store state for search when page reloads */
  useEffect(() => {
    if (urlData?.model) {
      const modelObject = modelsList?.find((p) => p.id === urlData?.model);
      if (modelObject) {
        setModel(modelObject);
        const sParams: ISearchParams = {
          model: modelObject,
        };
        setSearchParam(sParams);
      }
    } else {
      setModel(undefined);
    }
  }, [modelsList, urlData?.model]);

  useEffect(() => {
    if (urlData?.vin) {
      const sParams: ISearchParams = {
        vin: urlData.vin,
      };
      setVin(urlData.vin);
      setSearchParam(sParams);
    }
    if (urlData?.partNo) {
      const sParams: ISearchParams = {
        partNumber: urlData.partNo,
      };
      setPNumber(urlData.partNo);
      setSearchParam(sParams);
    }
    if (urlData?.tab) {
      setSelectedTab(tabs.find((t) => t.tabKey === urlData?.tab));
    }
  }, [urlData?.vin, urlData?.partNo, urlData?.tab]);

  /* Update local state and store state for search when page reloads */

  const onSearch = (modelFromUrl?: Model) => {
    const currentModel = modelFromUrl ?? model;
    let url = `/search?brand=${brandState?.selectedBrand?.brandID}`;
    const params: ISearchParams = {
      model: undefined,
      vin: '',
      partNumber: '',
    };

    if (currentModel) {
      params.model = currentModel;
      url = url + `&model=${currentModel.id}`;
    } else if (pNumber) {
      params.partNumber = pNumber;
      url = url + `&partNo=${pNumber}`;
    } else if (vin) {
      params.vin = vin;
      url = url + `&vin=${vin}`;
    }

    if (props?.setIsSearched) {
      props?.setIsSearched(true);
    }
    setSearchParam(params);
    history.replace(url);
  };

  useEffect(() => {
    if (
      (selectedTab.tabKey === SearchTabNames.Model && model) ||
      (selectedTab.tabKey === SearchTabNames.VINNumber && vin) ||
      (selectedTab.tabKey === SearchTabNames.ProductPartNumber && pNumber)
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [model, vin, pNumber]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onSearch();
    }
  };

  // search part-no on enter key

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });



  return (
    <>
      <div className="searchBox__outer">
        <ul className="searchBox__tabs font-xs text-gray-600 text-uppercase p-0 px-md-2 list-unstyled m-0 bg-white d-flex font-weight-medium overflow-auto text-nowrap">
          {tabs.map((p, index) => {
            return (
              <li
                key={`searchTab_${p.tabKey}_${index}`}
                onClick={() => {
                  setSelectedTab(p);
                  setModel(undefined);
                  setVin('');
                  setPNumber('');
                  // props?.setIsSearched(false);
                }}
                className={classnames({
                  'py-md-2 py-3 mx-md-3 mx-0 px-md-0 px-2 position-relative cursor-pointer':
                    true,
                  'searchBox__tabs--active text-gray-900':
                    selectedTab.tabKey === p.tabKey,
                })}
              >
                <span className="d-inline-flex">{p.tabTitle}</span>
              </li>
            );
          })}
        </ul>

        <div className="searchBox align-items-center py-md-2 px-md-0 p-3">
          {selectedTab.tabKey === SearchTabNames.Model && (
            <>
              <div className="px-4 border-right d-md-flex d-none align-items-center h-100">
                <img
                  src={brandState.selectedBrand?.brandIcon}
                  className="img-fluid mh-100"
                  alt={brandState.selectedBrand?.brandName ?? ''}
                />
              </div>
              <div className="searchBox__input pl-md-2">
                <DropdownList
                  filter="contains"
                  busy={!loaded || loading}
                  placeholder={translate('Home.selectModel')}
                  data={modelsList?.sort((a, b) =>
                    a.name.localeCompare(b.name)
                  )}
                  textField="name"
                  valueField="id"
                  defaultValue={model}
                  value={model}
                  disabled={!brand}
                  onChange={(modl) => {
                    setModel(modl);
                  }}
                ></DropdownList>
              </div>
            </>
          )}
          {selectedTab.tabKey === SearchTabNames.ProductPartNumber && (
            <div className="searchBox__input">
              <input
                type="text"
                placeholder={translate('Home.enterProductOrPartNo')}
                defaultValue={pNumber}
                //   value={pNumber}
                onChange={(e) => {
                  setPNumber(e.target.value);
                }}
              />
            </div>
          )}
          {selectedTab.tabKey === SearchTabNames.VINNumber && (
            <div className="searchBox__input">
              <input
                type="text"
                placeholder={translate('Home.enterVinNo')}
                //   value={vin}
                defaultValue={vin}
                onChange={(e) => {
                  setVin(e.target.value);
                }}
              />
            </div>
          )}
          <div className="searchBox__button d-md-block d-flex flex-column align-items-stretch">
            <Button
              onClick={() => {
                onSearch();
              }}
              className="Button Button__primary Button--radius font-weight-bold mr-md-1 mb-md-0 mb-3"
              size="sm"
              disabled={isDisabled}
            >
              {translate('Home.Search')}
            </Button>
            <Button
              size="xs"
              disabled={isProductLoading}
              onClick={() => {
                setFilterOpen(true);
              }}
              className={`px-2 text-uppercase ${
                brand && model?.id ? 'text-gray-700' : ''
                }`}
            >
              <i
                className={`icon-tool font-md mr-1 position-relative ${
                  brand && model?.id ? 'text-primary' : ''
                  }`}
              >
                {/* {props.filterSelected && <span className="filtered"></span>} */}
              </i>
              {translate('Home.Filter')}
            </Button>
          </div>
        </div>
      </div>

      <ProductFilterPopUp
        productFilters={model?.parameters}
        onSelect={(filter: ProductFilter[]) => {
          setProductFilter(filter);
        }}
        onClose={() => {
          setFilterOpen(false);
        }}
        selectedFilter={selectedFilter}
        filterOpen={filterOpen}
      ></ProductFilterPopUp>
    </>
  );
};

export default TabSearch;
