import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { State } from '../store/interfaces';
import {
  loggedInAction,
  logoutAction,
  redirectApplyAction,
  redirectSaveAction,
  profileUpdatedAction,
  getRolesSuccessAction,
} from '../store/reducers/authUser';
import { StatusCodes } from 'http-status-codes';
import { AuthService } from '../api';
import {
  ApiResponse,
  ICustomerBulkImportResponse,
  User,
  UserPermissionTypes,
  UserType,
} from '../api/models';
import { RouteKeys } from '../../containers/routes/route-keys';
import { useBrandsAndBranches } from './useBrandsAndBranches';
import api from '../../api';
import { toastr } from 'react-redux-toastr';
import { translate } from '../../utils/text-utils';

export const useAuth = () => {
  const authState = useSelector((state: State) => state.authUser);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [loginError, setLoginError] = useState<string | undefined>();
  const [updating, setUpdating] = useState<boolean>(false);
  const history = useHistory();
  const { getBrandAndBranches } = useBrandsAndBranches();
  const isSalesPerson: boolean = authState.userType === UserType.Salesperson;
  const login = async (
    data: {
      email: string;
      password: string;
    },
    setToken: (token?: string) => void,

    // redirectTo: string,
    onSuccess?: () => void
  ) => {
    setLoginError(undefined);
    setUpdating(true);
    try {
      const response = await api.auth.login({
        username: data.email,
        password: data.password,
      });

      if (response.response === 'Success') {
        setLoginError('');
        dispatch(
          loggedInAction({
            token: response.result?.userToken,
            userType: response.result?.userType,
            user: response.result?.user,
          })
        );
        // await getBrandAndBranches(response?.result?.userToken);
        // setToken(response.result?.userToken);
        history.replace(
          authState.redirectTo ||
            (response.result?.userType === UserType.Customer ||
            response.result?.userType === UserType.User
              ? RouteKeys.Home
              : RouteKeys.Dashboard)
        );
        redirectApply();
        if (onSuccess) onSuccess();
      } else {
        setLoginError(response?.errorMsg || 'Invalid Username or Password!');
      }
    } catch (error) {
      setLoginError(
        (error as any)?.response?.data?.errorMsg ||
          'Invalid Username or Password!'
      );
    }
    setUpdating(false);
  };

  const logout = (logoutLink: string) => {
    dispatch(logoutAction());
    history.push(logoutLink);
  };

  const getRoles = async () => {
    const response = await api.auth.getRoles();
    if (response.result) {
      dispatch(getRolesSuccessAction(response.result));
    } else {
      dispatch(getRolesSuccessAction(undefined));
    }
  };

  const hasRole = (role: UserPermissionTypes) => {
    // if (
    //   authState.userType === UserType.Customer ||
    //   authState.userType === UserType.User
    // ) {
    //   return true;
    // }
    return authState?.roles?.[role] ?? false;
  };

  const redirectSave = (to: string) => {
    dispatch(redirectSaveAction(to));
  };

  const setProfile = async (data: User, onSuccess?: () => void) => {
    dispatch(profileUpdatedAction(data));
  };

  const redirectApply = () => dispatch(redirectApplyAction());

  const customerBulkUploadPreview = async (
    file: File
  ): Promise<ApiResponse<{ data: ICustomerBulkImportResponse[] } | null>> => {
    try {
      setLoader(true);
      const response = await api.auth.bulkUploadPreviewCustomers(file);
      return response;
    } catch (e) {
      toastr.error('Error', 'File upload failed');

      return null;
    } finally {
      setLoader(false);
    }
  };

  const submitBulkCustomer = async (ids?: string[]) => {
    try {
      setLoader(true);
      const response: any = await api.auth.submitBulkCustomer(ids?.join(','));
      if (response) {
        return response;
      }
    } catch (e) {
      toastr.error(
        translate('common.error'),
        translate('toast.somethingWentWrong')
      );
      return null;
    } finally {
      setLoader(false);
    }
  };
  return {
    ...authState,
    isAuthenticated: !!authState.userType,
    profile: authState.profile,
    loginError,
    updating,
    loader,
    login,
    logout,
    redirectSave,
    redirectApply,
    setProfile,
    getRoles,
    hasRole,
    customerBulkUploadPreview,
    submitBulkCustomer,
    isSalesPerson,
  };
};
