import React, { useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';
import api from '../../../api';
import { FullPageLoader } from '../../../components/loader';
import { useCart } from '../../../core/hooks';
import { translate } from '../../../utils/text-utils';
import { UserHeader } from '../../header';
import { RouteKeys } from '../../routes/route-keys';
import Cart, { CartComponent } from './Cart';
import { InPageConfirmation } from './components/cart-confirm-popup';
import { ConfirmSummary } from './components/confirm-summary';

const CartConfirmation: React.SFC = () => {
  const { getCart: getCart, cart } = useCart();
  const [loader, setLoader] = useState(false);
  const [orderId, setOrderId] = useState<string>();
  const [status, setStatus] = useState<string>();

  const onSubmit = async () => {
    try {
      setLoader(true);
      const response = await api.model.submitRequest(cart?.cartID!);

      getCart();
      setOrderId(response.order_id);
      setStatus(response?.dynamicOrderStatus);
    } catch (err) {
      toastr.error('Error', 'Somethign went wrong');
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <UserHeader />
      <div className={`container ${orderId ? 'px-0' : ''}`}>
        {!orderId && (
          <div className="row gutter-12 pt-4">
            <div className="col-12 col-lg-8 pb-4">
              {(cart?.items?.length ?? 0) > 0 && (
                <InPageConfirmation
                  onConfirm={onSubmit}
                  text={translate('cartConfirmation.cartVerifyMessage')}
                ></InPageConfirmation>
              )}
            </div>
            <div className="col-12 col-lg-4">
              <div className="cartBlock bg-white">
                <CartComponent
                  disableSubmit={true}
                  showHeader={true}
                  cart={cart}
                />
              </div>
            </div>
          </div>
        )}
        {orderId && (
          <>
            <ConfirmSummary
              redirectionRoute={RouteKeys.Orders + '/' + status}
              header={translate('orders.orderSubmittedSuccessfully')}
              text={translate('orders.pleaseReferRequestId').replace(
                '{orderId}',
                orderId
              )}
            ></ConfirmSummary>
          </>
        )}
      </div>

      {loader && <FullPageLoader></FullPageLoader>}
    </>
  );
};

export default CartConfirmation;
