import React, { useEffect, useState } from 'react';
import Button from '../../../components/button';
import './cart.scss';
import './cartItem.scss';
import '../../../containers/search-tab/search-box.scss';
// @ts-ignore
import { useCart } from '../../../core/hooks/useCart';
import empty from './empty.png';
import classnames from 'classnames';
import { useQuotes } from '../../../core/hooks/useQuotes';
import { TextInput } from '../../../components/form-group';
import { QuoteItem } from './quote-item';
import { CartItemComponent } from './cart-item';
import { useHistory } from 'react-router-dom';
import { RouteKeys } from '../../routes/route-keys';
import { ICartData } from '../../../models/order-models';
import { IQuotation, UserType } from '../../../core/api/models';
import { useDispatch, useSelector } from 'react-redux';
import { State, cartOpen, quoteOpen, checkInAction } from '../../../core/store';
import { useBrandsAndBranches } from '../../../core/hooks/useBrandsAndBranches';
import { useTranslation } from 'react-i18next';
import { translate } from '../../../utils/text-utils';
import Currency from 'react-currency-formatter';
import { CurrencyFormatter } from '../../../components/currency-formatter';
import { DeleteConfirmationDialog } from './components/delete-confirmation';
import { toastr } from 'react-redux-toastr';
import { ContainerLoader, FullPageLoader } from '../../../components/loader';

interface CustomProps {
  popup?: boolean;
  hideSubmit?: boolean;
  onToggle?: (isVisible: boolean) => void;
  setShowCartPopup?: (status: boolean) => void;
}
export enum TabNames {
  Cart = 'Cart',
  Quotes = 'Quotes',
}

const Cart: React.FunctionComponent<CustomProps> = (props: CustomProps) => {
  const authState = useSelector((state: State) => state.authUser);
  const isPopup = props.popup ?? false;

  const { getCart, cart, loaded } = useCart();
  const { brandState } = useBrandsAndBranches();
  const { getQuotations, quotations, loaded2 } = useQuotes();

  const [tabName, setTabName] = React.useState(TabNames.Cart);

  const [popupToggle, setPopupToggle] = React.useState<boolean>(!isPopup);

  const cartOpenState = useSelector((state: State) => state.cart.cartOpen);
  const quoteOpenState = useSelector((state: State) => state.cart.quoteOpen);

  useEffect(() => {
    props.onToggle?.(popupToggle);
  }, [popupToggle]);

  useEffect(() => {
    if (props.setShowCartPopup) {
      setPopupToggle(isPopup);
    }
  }, [isPopup]);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  React.useEffect(() => {
    setRendered(true);
    if (authState?.userToken) {
      getCart();
      getQuotations();
    }
  }, [
    brandState?.selectedBrand?.brandID,
    brandState?.selectedBranch?.warehouseID,
  ]);

  const [isRendered, setRendered] = useState(false);
  const quoteList = quotations ?? [];

  React.useEffect(() => {
    if (cartOpenState === true) {
      setTabName(TabNames.Cart);
    }
    if (quoteOpenState === true) {
      setTabName(TabNames.Quotes);
    }
  }, [cartOpenState, quoteOpenState]);

  const accountCode = authState.checkedInCustomer?.brandWithAccountNumber?.find(
    (p) => p.brandID === brandState?.selectedBrand?.brandID
  )?.accountNumber;

  return (
    <>
      {loaded && (
        <div
          className={`cartBlock ${cartOpenState === true || quoteOpenState === true ? 'active' : ''
            }`}
        >
          {authState.userType === UserType.Salesperson &&
            authState.checkedInCustomer && (
              <div className="px-lg-4 p-3 border-bottom bg-white">
                <div className="row gutter-12 align-items-center">
                  <div className="col minw-0">
                    <h6 className="text-truncate m-0 font-base">
                      {authState.checkedInCustomer?.customerName ?? ''}
                    </h6>
                    {accountCode != null && (
                      <p className="m-0 font-sm">
                        <span className="text-muted">
                          {translate('customerListing.accountCode')}
                          {' : '}
                        </span>
                        {
                          authState.checkedInCustomer?.brandWithAccountNumber?.find(
                            (p) =>
                              p.brandID === brandState?.selectedBrand?.brandID
                          )?.accountNumber
                        }
                      </p>
                    )}
                  </div>
                  <div className="col-auto">
                    {' '}
                    <Button
                      className="Button__primary promo-btn font-md"
                      size="sm"
                      type="submit"
                      onClick={() => {
                        if (authState.checkedInCustomer) {
                          dispatch(checkInAction(authState.checkedInCustomer));
                        }
                      }}
                    >
                      {translate('Home.Checkout')}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          <div className="bg-white px-lg-4 px-3 mb-1 d-lg-block d-none border-bottom border-light">
            <div className="row gutter-10">
              <div className="col-auto">
                <ul className="searchBox__tabs border-0 font-xs text-gray-600 p-0 list-unstyled m-0 d-inline-flex font-weight-medium overflow-auto text-nowrap">
                  <li
                    onClick={() => {
                      setTabName(TabNames.Cart);
                      if (props?.setShowCartPopup) {
                        props.setShowCartPopup(true);
                      } else {
                        setPopupToggle(true);
                      }
                    }}
                    className={classnames({
                      'position-relative mr-5 cursor-pointer': true,
                      'searchBox__tabs--active text-gray-900 cartBlock__header mr-5':
                        tabName === TabNames.Cart,
                    })}
                  >
                    {t('cart.My_Cart')}{' '}
                    {/* <span className="text-gray-600 ml-2">({myList?.length || 0})</span> */}
                  </li>
                  <li
                    onClick={() => {
                      setTabName(TabNames.Quotes);

                      if (props?.setShowCartPopup) {
                        props.setShowCartPopup(true);
                      } else {
                        setPopupToggle(true);
                      }
                    }}
                    className={classnames({
                      'position-relative cursor-pointer': true,
                      'searchBox__tabs--active text-gray-900 cartBlock__header':
                        tabName === TabNames.Quotes,
                    })}
                  >
                    {translate('Home.My_Quotes')}{' '}
                    {/* <span className="text-gray-600 ml-2">({0})</span> */}
                  </li>
                </ul>
              </div>
              <div className="col minw-0 d-flex align-items-center py-2">
                <div className="cartItem__product-count position-relative justify-content-end">
                  <div className="price-block p-0">
                    {!!cart?.items?.length && tabName === TabNames.Cart && (
                      <span className="font-md text-gray-600">
                        {translate('Home.Items_in_Cart')}{' '}
                        <span className="text-dark font-weight-medium">
                          {cart.items?.length}
                        </span>
                      </span>
                    )}
                    {!!quotations?.length && tabName === TabNames.Quotes && (
                      <span className="font-md cursor-pointer text-gray-600 d-inline-block">
                        {translate('Home.Items_in_Quote')}{' '}
                        <span className="text-dark font-weight-medium">
                          {quoteList?.length}
                        </span>
                      </span>
                    )}
                  </div>
                </div>
                {(isPopup || props.setShowCartPopup) && (
                  <div
                    onClick={() => {
                      if (props.setShowCartPopup) {
                        props.setShowCartPopup(!isPopup);
                      } else {
                        setPopupToggle(!popupToggle);
                      }
                    }}
                    className="cursor-pointer border border-primary rounded border-width-2 font-weight-bold d-flex align-items-center justify-content-center"
                    style={{ width: '28px', height: '28px' }}
                  >
                    <i
                      className={
                        (!popupToggle
                          ? 'icon-chevron-down'
                          : 'icon-chevron-up') +
                        ` text-primary font-xxxs font-weight-bold`
                      }
                    ></i>
                  </div>
                )}
              </div>
            </div>
          </div>
          {popupToggle && (
            <>
              {cartOpenState && (
                <div className="border-bottom p-3 d-flex align-items-center font-weight-bold font-xl text-gray-900 bg-white d-lg-none">
                  <i
                    className="icon-arrow-left mr-3 font-lg"
                    onClick={() => dispatch(cartOpen(!cartOpenState))}
                  ></i>
                  {translate('Home.My_Cart')}
                </div>
              )}
              {quoteOpenState && (
                <div className="border-bottom p-3 d-flex align-items-center font-weight-bold font-xl text-gray-900 bg-white d-lg-none">
                  <i
                    className="icon-arrow-left mr-3 font-lg"
                    onClick={() => dispatch(quoteOpen(!quoteOpenState))}
                  ></i>
                  {translate('Home.My_Quotations')}
                </div>
              )}
              <div
                className={`cartPopup custom-scroll-vertical m-0 ${popupToggle ? 'isOpen' : ''
                  }`}
              >
                {tabName === TabNames.Cart && (
                  <CartComponent cart={cart}></CartComponent>
                )}
                {tabName === TabNames.Quotes && (
                  <QuoteComponent quoteList={quoteList ?? []}></QuoteComponent>
                )}
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Cart;

export const CartComponent = (props: {
  cart?: ICartData;
  disableSubmit?: boolean;
  showHeader?: boolean;
  disableCounter?: boolean;
  canEditCart?: boolean;
}) => {
  const showHeader = props.showHeader ?? false;
  const [showConfirm, setShowConfirm] = useState(false);
  const [isClearCart, setIsClearCart] = useState(false);

  const disableCounter = props.disableCounter ?? false;
  const history = useHistory();
  const cart = props.cart;
  const cartItems = cart?.items ?? [];
  const disableSubmit = props.disableSubmit ?? false;
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const { t } = useTranslation();
  const { bulkDeleteFromCart } = useCart();
  const [loading, setLoading] = useState(false);

  if (!!!cart?.items?.length) {
    return (
      <div className="cartBlock__item">
        <div className="cartBlock__itemHead">
          {translate('Home.Your_Cart_is_empty')}
        </div>
        <div className="cartBlock__img">
          <img src={empty} alt="" />
        </div>
      </div>
    );
  }
  const delteItems = async (reason: string, otherReason?: string) => {
    try {
      setLoading(true);
      await bulkDeleteFromCart(
        selectedItems.join(','),
        cart?.cartID,
        reason,
        otherReason,
        isClearCart
      ).then(() => {
        setSelectedItems([]);
      }).catch(() => {
        toastr.error('Error', 'Something went wrong');
      });
    } catch (err) {
      toastr.error('Error', 'Something went wrong');
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {showHeader && (
        <div className="cartBlock__head ">
          <ul className="searchBox__tabs border-0 font-xs text-gray-600 p-0 mx-2 list-unstyled m-0 bg-white d-flex font-weight-medium overflow-auto text-nowrap">
            <li className="position-relative mr-5 cursor-pointer text-gray-900 cartBlock__header mr-5">
              {t('cart.My_Cart')}{' '}
            </li>
          </ul>
          <div className="cartItem__product-count position-relative justify-content-end">
            <div className="price-block pr-0">
              <span className="font-md text-gray-600 transform-btm d-inline-block">
                {translate('Home.Items_in_Cart')}{' '}
                <span className="text-dark">{cartItems.length}</span>
              </span>
            </div>
          </div>
        </div>
      )}
      <div className="px-4 pb-2 d-flex flex-wrap align-items-center">
        {/* <div>
          <input
            onClick={(event: any) => {
              if (event.target.checked) {
                setSelectedItems(cartItems?.map((p) => p.cartItemID) ?? []);
              } else {
                setSelectedItems([]);
              }
            }}
            type="checkbox"
            checked={cartItems.length === selectedItems.length}
            id="checkone1"
          />
          <label
            className="font-xs form-check-label text-muted checkbox-sm"
            htmlFor="checkone1"
          >
            Select all
          </label>
        </div> */}
        {selectedItems.length > 0 && (
          <span
            onClick={() => {
              setIsClearCart(false);
              setShowConfirm(true);
            }}
            className="ml-3 mb-0 font-xs text-primary text-underline cursor-pointer"
          >
            Delete {selectedItems.length === 1 ? 'item' : 'items'}
          </span>
        )}
        {!props?.canEditCart && (
          <div className="ml-auto pl-3">
            <span
              onClick={() => {
                setIsClearCart(true);
                setShowConfirm(true);
              }}
              className="font-xs text-primary text-underline cursor-pointer"
            >
              {translate('cart.clearCart')}
            </span>
          </div>
        )}
      </div>
      <div className="bg-white d-flex flex-column overflow-auto">
        {cartItems?.map((cartItem, index) => {
          return (
            <CartItemComponent
              canEditCart={props?.canEditCart}
              isChecked={selectedItems.indexOf(cartItem.cartItemID) !== -1}
              onSelectionChange={() => {
                let clone = [...selectedItems];
                if (clone.indexOf(cartItem.cartItemID) === -1) {
                  clone.push(cartItem.cartItemID);
                } else {
                  clone = clone.filter((p) => p !== cartItem.cartItemID);
                }
                setSelectedItems(clone);
              }}
              key={`${cartItem.cartID}_${index}`}
              isViewOnly={disableCounter}
              item={cartItem}
              cartId={cart.cartID}
            ></CartItemComponent>
          );
        })}
      </div>
      <div className="border-top position-sticky bottom-0 bg-white zIndex-9">
        <div className="px-lg-4 p-3 text-right font-md">
          <div className="row gutter-8 pb-1">
            <div className="col-xl-9 col-8">{translate('Home.Sub_Total')}</div>
            <div className="col-xl-3 col-4">
              <span className="font-weight-medium">
                <CurrencyFormatter quantity={cart?.orderSubtotal} />
              </span>
            </div>
          </div>
          <div className="row gutter-8 pb-1">
            <div className="col-xl-9 col-8">
              {translate('Home.Shipping_Fee')}
            </div>
            <div className="col-xl-3 col-4">
              <span className="font-weight-medium">
                <CurrencyFormatter quantity={cart?.shippingAmount} />
              </span>
            </div>
          </div>
          <div className="row gutter-8 pb-1">
            <div className="col-xl-9 col-8">
              {translate('Home.VAT')}{' '}
              {cart?.taxPercent ? cart?.taxPercent.toNumber() + ' % ' : ''}
            </div>
            <div className="col-xl-3 col-4">
              <span className="font-weight-medium">
                <CurrencyFormatter quantity={cart?.orderTaxAmount} />
              </span>
            </div>
          </div>
          <div className="row gutter-8 pb-1">
            <div className="col-xl-9 col-8">{translate('Home.Total')}</div>
            <div className="col-xl-3 col-4">
              <span className="font-weight-medium">
                <CurrencyFormatter quantity={cart?.orderTotalAmount} />
              </span>
            </div>
          </div>
        </div>
        {!disableSubmit && (
          <div className="bg-white">
            {/* <div className="px-4 mb-3 pb-1">
              <div className="row">
                <div className="col-md-10">
                  <div className="position-relative row gutter-5">
                    <div className="col">
                      <TextInput
                        label=""
                        placeholder="Promo Code"
                        type="text"
                        size="sm"
                      ></TextInput>
                    </div>
                    <div className="col-auto">
                      {' '}
                      <Button
                        className=" Button__primary-outline promo-btn"
                        size="sm"
                        type="submit"
                      >
                        {translate('Home.Apply')}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="px-4 pb-3">
              <Button
                className="mb-1 Button__primary w-100 text-capitalize d-flex justify-content-between rounded py-3 px-3"
                type="submit"
                onClick={() => {
                  history.push(RouteKeys.ConfirmCart);
                }}
              >
                <span className="font-xl pl-1">
                  {translate('Home.Send_Request')}
                </span>
                <span className="font-xl pr-1">
                  {' '}
                  <CurrencyFormatter quantity={cart?.orderTotalAmount} />
                </span>
              </Button>
            </div>
          </div>
        )}
      </div>
      <DeleteConfirmationDialog
        title={!isClearCart ? `${translate('cart.deleteConfirm')} ${selectedItems.length === 1
          ? `${translate('cart.anItem')}`
          : selectedItems.length + ` ${translate('cart.items')}`
          } ${translate('cart.fromCart')}` : `${translate('cart.clearCartItems')}`}
        onDismiss={() => {
          setShowConfirm(false);
        }}
        onConfirm={(reason: string, otherReason?: string) => {
          setShowConfirm(false);
          delteItems(reason, otherReason);
        }}
        open={showConfirm}
      ></DeleteConfirmationDialog>
      {loading && (
        <div className="position-absolute top-0 left-0 h-100 w-100">
          <FullPageLoader></FullPageLoader>
        </div>
      )}
    </>
  );
};

export const QuoteComponent = (props: {
  quoteList: IQuotation[];
  showHeader?: boolean;
  disableSubmit?: boolean;
}) => {
  const quoteList = props.quoteList;
  const history = useHistory();
  const disableSubmit = props.disableSubmit ?? false;
  return (
    <>
      {!!quoteList?.length ? (
        <>
          {props.showHeader && (
            <div className="cartBlock__head">
              <ul className="searchBox__tabs border-0 font-xs text-gray-600 p-0 mx-2 list-unstyled m-0 bg-white d-flex font-weight-medium overflow-auto text-nowrap">
                <li className="position-relative mr-5 cursor-pointer text-gray-900 cartBlock__header mr-5">
                  {translate('Home.My_Quote')}
                </li>
              </ul>
              <div className="cartItem__product-count position-relative justify-content-end">
                <div className="price-block pr-0">
                  <span className="font-md text-gray-600 transform-btm d-inline-block">
                    {translate('Home.Items_in_Quote')}{' '}
                    <span className="text-dark">{quoteList.length}</span>
                  </span>
                </div>
              </div>
            </div>
          )}
          <div className="bg-white h-100 d-flex flex-column overflow-auto">
            {quoteList?.map((pdt, index) => {
              return (
                <QuoteItem
                  key={`${pdt.partNumber}_${index}`}
                  item={pdt}
                  disabled={props?.showHeader}
                ></QuoteItem>
              );
            })}
          </div>

          {!!quoteList?.length && !disableSubmit && (
            <div className="px-lg-4 p-3">
              <Button
                className="my-1 Button__primary w-100 text-capitalize d-flex justify-content-center rounded py-3"
                type="submit"
                onClick={() => {
                  history.push(RouteKeys.ConfirmQuote);
                }}
              >
                <span className="font-xl">
                  {translate('Home.Send_Request')}
                </span>
              </Button>
            </div>
          )}
        </>
      ) : (
        <>
          <div className="cartBlock__item">
            <div className="cartBlock__itemHead">
              {translate('Home.Your_Quote_is_empty')}
            </div>
            <div className="cartBlock__img">
              <img src={empty} alt="" />
            </div>
          </div>
        </>
      )}
    </>
  );
};
