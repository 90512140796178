/* eslint jsx-a11y/anchor-is-valid: "off" */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import './UserHeader.scss';
import './dropdown.scss';
import { SideMenu } from '.';
import { translate } from '../../utils/text-utils';

import LogoImage from '../../assets/logo/images/logo.png';
import {
  ArabicLocale,
  changeLanguageAction,
  EnglishLocale,
  ILocale,
  State,
  cartOpen,
  quoteOpen,
  setNotificationPopupVisibilityAction,
} from '../../core/store';
import { RouteKeys } from '../routes/route-keys';
import BrandSelection from './BrandSlelection';
import { useBrandsAndBranches } from '../../core/hooks/useBrandsAndBranches';
import { useAuth, useCart, useProduct } from '../../core/hooks';
import { useQuotes } from '../../core/hooks/useQuotes';
import i18n from '../../i18n';
import {
  ISearchParams,
  Model,
  ProductFilter,
  UserPermissionTypes,
  UserType,
} from '../../core/api/models';
import SideMenuMob from './SideMenuMob';
import { NotificationPopup } from './notification-popup';
import { useNotifications } from '../../core/hooks/useNotifications';
import TabSearch from '../search-tab/search-tab';

interface CustomProps {
  menuVisible?: boolean;
  mobHeader?: boolean;
  showBrand?: boolean;
}

const UserHeaderCanvas: React.SFC<CustomProps> = (props: CustomProps) => {
  const {
    getCarsV2,
    searchCarsvin,
    setSearchParam,
    totalRecord,
    loaded,
    setProductFilter,
    selectedFilter,
    model,
    setModel,
    brand,
    setBrand,
  } = useProduct();
  const { notificationState } = useNotifications();
  const { hasRole } = useAuth();
  const history = useHistory();
  const showBrand = props.showBrand ?? false;

  const cartOpenState = useSelector((state: State) => state.cart.cartOpen);
  const quoteOpenState = useSelector((state: State) => state.cart.quoteOpen);

  const [stateExpand, setStateExpand] = React.useState<boolean>(false);
  const [menuAccountOpen, toggleMenuAccountOpen] =
    React.useState<boolean>(false);
  const [brandSelectionOpen, setBrandSelectionOpen] =
    React.useState<boolean>(false);

  const [searched, setSearched] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  // const [brand, setBrand] = useState('');
  // const [model, setModel] = useState('');
  const [modelDetails, setModelDetails] = useState<Model>();
  const [vinNumber, setVinNumber] = useState('');
  const [filterOpen, setFilterOpen] = useState(false);
  const [searchFilter, toggleSearchFilter] = useState(false);
  const { cart } = useCart();
  const { quotations } = useQuotes();

  const authState = useSelector((state: State) => state.authUser);
  const domain = authState?.userToken ? '/' : '/dashboard';
  const { brandState, getBrandAndBranches } = useBrandsAndBranches();
  const settings = useSelector((state: State) => state.settings);
  const dispatch = useDispatch();
  const onLanguageChange = (language: ILocale) => {
    //  i18n.changeLanguage(language.languageKey);
    dispatch(changeLanguageAction(language.languageID));
    // const h1 = document.getElementsByTagName('html')[0]; // Get the first <h1> element in the document
    // const att = document.createAttribute('dir'); // Create a "class" attribute
    // att.value = language.isRtl ? 'rtl' : 'ltr'; // Set the value of the class attribute
    // h1.setAttributeNode(att);
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };

  useEffect(() => {
    if (authState?.userToken) {
      getBrandAndBranches(authState?.userToken);
    }
  }, [authState.userToken]);
  const unreadNotificationCount =
    notificationState.notifications?.filter((p) => !p.isRead)?.length ?? 0;
  const getCars = (
    searchbrand?: string | undefined,
    searchmodel?: string | undefined,
    searchVin?: string | undefined,
    filters?: ProductFilter[],
    pageNo?: number
  ) => {
    if (searchbrand && searchmodel) {
      getCarsV2(searchmodel, filters, pageNo);
    } else if (searchVin) {
      searchCarsvin(searchVin, filters, pageNo);
    }
  };
  const { loadNotifications } = useNotifications();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      loadNotifications();
    }
  }, []);

  const onSearch = (selectedBrand: string, selectedmodel: string) => {
    let url = `/search-result?b=${selectedBrand}`;
    if (selectedmodel) {
      url = url + `&m=${selectedmodel}`;
    }

    // history.replace(url);
    setBrand(selectedBrand);
    setModel(selectedmodel);
    setVinNumber('');
    if (selectedBrand && selectedmodel) {
      setSearched(true);
      // window.history.pushState(
      //   {
      //     id: 'homepage',
      //   },
      //   'pageTitle',
      //   url
      // );
      history.replace(url);
      setIsLoading(true);
      getCars(selectedBrand, selectedmodel, '', selectedFilter, 1);
      setFilterOpen(true);
    }
  };

  const onSearchVin = (vin: string) => {
    setSearched(true);
    setIsLoading(true);
    getCars('', '', vin, selectedFilter, 1);
    setBrand('');
    setModel('');
    setVinNumber(vin);
    const url = `/search-result?vin=${vin}`;
    window.history.pushState(
      {
        id: 'homepage',
      },
      'pageTitle',
      url
    );
    // history.replace(url);
  };
  const { pathname } = useLocation();
  return (
    <>
      <header className="header border-bottom d-lg-block d-none">
        <div className="header__bottom py-sm-1 py-2">
          <div className="container py-1">
            <div className="row gutter-10 align-items-center">
              <div className="col-auto">
                <div className="position-relative menu-exp">
                  <div
                    className={`text-gray-700 cursor-pointer ${
                      stateExpand === true ? 'active' : ''
                    }`}
                    onClick={() => setStateExpand(!stateExpand)}
                  >
                    <i className="icon-menu d-block"></i>
                    <span className="d-block text-center font-weight-medium">
                      {translate('Header.MENU')}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-auto ">
                <Link to={`${domain}`} className="Logo">
                  <img src={LogoImage} alt="Logo" />
                </Link>
              </div>
              {showBrand && (
                <div className="col-auto mt-sm-0 mt-3">
                  <div
                    className="d-inline-flex align-items-baseline font-md text-gray-600 cursor-pointer"
                    onClick={() => setBrandSelectionOpen(true)}
                  >
                    <span className="font-weight-bold text-gray-900 border-bottom border-dark border-width-2 mr-2">
                      {brandState?.selectedBrand?.brandName ?? ''}
                    </span>
                    {brandState?.selectedBranch?.warehouseName ?? ''}
                    <i className="icon-arrow-down text-primary ml-2 pl-1 font-sm"></i>
                  </div>
                </div>
              )}
              <div className="col-12 col-md d-md-flex justify-content-end d-none d-md-block">
                <ul className="header__nav d-flex align-items-center list-unstyled m-0 flex-wrap font-weight-bold line-height">
                  <li
                    onClick={() => {
                      history.push(`${RouteKeys.Offers}`);
                    }}
                    className="header__nav-inner position-relative ml-xl-5 ml-4 d-flex align-items-center cursor-pointer"
                  >
                    <i className="icon-offers"></i>
                    <p className="m-0 font-md pl-2 text-uppercase text-gray-700 d-lg-inline-block d-none">
                      {translate('Header.OFFERS')}
                    </p>
                  </li>
                  {((authState.userType === UserType.Salesperson &&
                    authState.checkedInCustomer &&
                    hasRole(UserPermissionTypes.bulk_order)) ||
                    ((authState.userType === UserType.Customer ||
                      authState.userType === UserType.User) &&
                      hasRole(UserPermissionTypes.bulk_order))) && (
                    <li className="header__nav-inner position-relative ml-xl-5 ml-4">
                      <Link
                        to={RouteKeys.BulkOrder}
                        className="d-flex align-items-center text-gray-700"
                      >
                        <i className="icon-bulk h5 m-0"></i>
                        <p className="m-0 font-md pl-2 text-uppercase d-lg-inline-block d-none align-text">
                          {translate('Header.BULK_ORDER')}
                        </p>
                      </Link>
                    </li>
                  )}
                  <li className="header__nav-inner position-relative ml-xl-5 ml-4 d-lg-inline-flex align-items-center d-none">
                    <span
                      className="m-0 font-md font-arabic line-height-normal cursor-pointer align-text-top"
                      onClick={() => {
                        if (settings.locale.languageID === 1) {
                          onLanguageChange(new ArabicLocale());
                        } else {
                          onLanguageChange(new EnglishLocale());
                        }
                      }}
                    >
                      {settings?.locale?.languageID === 1 ? 'عربي' : 'English'}
                    </span>
                  </li>
                  <li
                    className={`header__nav-notification position-relative ml-xl-5 ml-4 d-flex cursor-pointer ${
                      unreadNotificationCount > 0 ? 'mr-3' : ''
                    }`}
                    onClick={() => {
                      dispatch(setNotificationPopupVisibilityAction(true));
                    }}
                  >
                    <i className="icon-due-notification"></i>
                    {unreadNotificationCount > 0 && (
                      <span className="noti position-absolute">
                        {unreadNotificationCount}
                      </span>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
      {props.mobHeader && (
        <header className="d-lg-none header-mobile py-5 position-relative mb-4">
          <div className="container-fluid">
            <div className="row gutter-12">
              <div className="col">
                <h1 className="text-white mb-0 font-weight-bold">
                  {translate('Header.Discover')}
                </h1>
                <p className="text-white font-sm mb-0">
                  {translate('Header.Everthing_you_needed_for_your_vehicle')}
                </p>
              </div>
              <div className="col-auto">
                <div className="pt-3 mt-1 d-flex align-items-center">
                  <i className="icon-social-media h5 mb-0 text-white mr-2 position-relative">
                    {unreadNotificationCount > 0 && (
                      <span className="noti-count font-xs text-white position-absolute rounded-pill text-center">
                        {unreadNotificationCount}
                      </span>
                    )}
                  </i>
                  <div
                    className={`language-switch rounded-pill d-inline-flex align-items-center text-center position-relative ${
                      settings.locale.languageID === 1 ? 'en' : 'ar'
                    }`}
                    onClick={() => {
                      if (settings.locale.languageID === 1) {
                        onLanguageChange(new ArabicLocale());
                      } else {
                        onLanguageChange(new EnglishLocale());
                      }
                    }}
                  >
                    <span className="en">EN</span>
                    <span className="ar">AR</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mob-search position-absolute w-100 bg-white rounded d-flex no-gutters align-items-center shadow">
            <div className="col px-2 minw-0">
              <div
                className="d-inline-flex align-items-baseline font-md text-gray-600 cursor-pointer w-100"
                onClick={() => setBrandSelectionOpen(true)}
              >
                <span className="font-weight-bold text-gray-900 text-uppercase text-nowrap">
                  {brandState?.selectedBrand?.brandName ?? ''} -&nbsp;
                </span>
                <span className="text-truncate">
                  {brandState?.selectedBranch?.warehouseName ?? ''}
                </span>
                <i className="icon-arrow-down text-gray-700 ml-2 pl-1 font-xxxs"></i>
              </div>
            </div>
            <div className="col-auto">
              <button
                className="btn btn-primary"
                onClick={() => {
                  // toggleSearchFilter(true)
                  const params: ISearchParams = {
                    model: undefined,
                    vin: '',
                    partNumber: '',
                  };
                  setSearchParam(params);
                  history.push(`${RouteKeys.SearchResult}`);
                }}
              >
                {translate('Header.Search')}
              </button>
            </div>
          </div>
        </header>
      )}
      <SideMenu
        isExpand={stateExpand}
        expandToggle={() => setStateExpand(!stateExpand)}
        menuVisible={props.menuVisible}
      />
      {menuAccountOpen && (
        <SideMenuMob
          isExpand={menuAccountOpen}
          expandToggle={() => setStateExpand(!menuAccountOpen)}
          menuVisible={props.menuVisible}
        />
      )}
      <BrandSelection
        isOpen={brandSelectionOpen}
        togglePopup={() => setBrandSelectionOpen(false)}
      />
      <footer className="d-lg-none position-fixed bottom-0 left-0 w-100 zIndex-9 bg-white text-center footer-mobile">
        <div className="container-fluid">
          <div className="row gutter-12">
            <div className="col">
              <Link
                to="/"
                onClick={() => {
                  if (menuAccountOpen === true) {
                    toggleMenuAccountOpen(false);
                  }
                }}
                className={`pt-2 pb-3 footer-menu d-inline-block  ${
                  pathname === '/' && !menuAccountOpen ? 'active' : ''
                }`}
              >
                <i className="icon-footer-home h4 mb-1"></i>
                <p className="m-0 font-xs">{translate('Header.Home')}</p>
              </Link>
            </div>
            <div className="col">
              <div onClick={() => toggleMenuAccountOpen(true)}>
                <span
                  className={`pt-2 pb-3 footer-menu d-inline-block cursor-pointer ${
                    menuAccountOpen ? 'active' : ''
                  }`}
                >
                  <i className="icon-footer-account h4 mb-1"></i>
                  <p className="m-0 font-xs">{translate('Header.Account')}</p>
                </span>
              </div>
            </div>
            <div className="col">
              <div
                className={`pt-2 pb-3 footer-menu ${
                  pathname === 'search' ? 'active' : ''
                }`}
                onClick={() => {
                  toggleMenuAccountOpen(false);
                  toggleSearchFilter(true);
                }}
              >
                <i className="icon-footer-search h4 mb-1"></i>
                <p className="m-0 font-xs">{translate('Header.Search')}</p>
              </div>
            </div>
            <div className="col">
              <div
                className="pt-2 pb-3 footer-menu"
                onClick={() => dispatch(quoteOpen(!quoteOpenState))}
              >
                <i className="icon-footer-quotes h4 mb-1 position-relative">
                  {!!quotations?.length && (
                    <span className="footer-count position-absolute bg-primary text-white font-xxs font-weight-bold rounded-pill">
                      {quotations?.length}
                    </span>
                  )}
                </i>
                <p className="m-0 font-xs">{translate('Header.Quotes')}</p>
              </div>
            </div>
            <div className="col">
              <div
                className="pt-2 pb-3 footer-menu"
                onClick={() => dispatch(cartOpen(!cartOpenState))}
              >
                <i className="icon-footer-cart h4 mb-1 position-relative">
                  {!!cart?.items?.length && (
                    <span className="footer-count position-absolute bg-primary text-white font-xxs font-weight-bold rounded-pill">
                      {cart?.items?.length}
                    </span>
                  )}
                </i>

                <p className="m-0 font-xs">{translate('Header.Cart')}</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {searchFilter && (
        <div className="d-lg-none position-fixed top-0 left-0 w-100 h-100 bg-body zIndex-9">
          <div className="border-bottom p-3 d-flex align-items-center font-weight-bold font-xl text-gray-900 bg-white">
            <i
              className="icon-arrow-left mr-3 font-lg"
              onClick={() => toggleSearchFilter(false)}
            ></i>
            {brandState?.selectedBrand?.brandName ?? ''}{' '}
            {brandState?.selectedBranch?.warehouseName ?? ''}
          </div>
          <TabSearch
            setIsSearched={(data) => {
              history.push(`${RouteKeys.SearchResult}`);
            }}
          />
        </div>
      )}
      {notificationState.isPopupOpened && (
        <NotificationPopup
          title="Notifications"
          onClose={() => {
            dispatch(setNotificationPopupVisibilityAction(false));
          }}
        />
      )}
    </>
  );
};

export default UserHeaderCanvas;
