/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import Button from '../../components/button';
import '../../assets/sass/common.scss';
import '../../assets/logo/sass/logo.scss';

import LogoImage from '../../assets/logo/images/logo.png';
import { Link } from 'react-router-dom';
interface CustomState {
  activeClass: string;
}
const domain = '/';

class HeaderCanvas extends Component<{}, CustomState> {
  constructor(props: any) {
    super(props);
    this.state = {
      activeClass: '',
    };
  }

  render() {
    return (
      <header className="header header--landing d-lg-block d-none">
        <div className="header__bottom py-lg-2 py-3">
          <div className="container">
            <div className="row">
              <div className="col-6 col-sm-6 d-flex align-items-center ">
                <Link to={`${domain}`} className="Logo">
                  <img src={LogoImage} alt="Logo" />
                </Link>
              </div>
              {/* <div className="col-6 col-sm-6 justify-content-end d-flex">
                <Button
                  type="button"
                  className="Button__teritary-outline"
                  align="left"
                  size="sm"
                >
                  New Account
                </Button>
              </div> */}
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default HeaderCanvas;
