import React from 'react';
import Counter from '../../../components/counter';
import { IQuotation } from '../../../core/api/models';
import { useQuotes } from '../../../core/hooks/useQuotes';

export const QuoteCounter = (props: {
  item: IQuotation;
  disabled?: boolean;
}) => {
  const item = props.item;
  const isDisabled = props.disabled ?? false;
  const { updateQuotation } = useQuotes();
  return (
    <Counter
      isLoading={item.isLoading}
      value={item?.quantity!}
      onChange={(count) => {
        const clone = { ...item };
        clone.quantity = count;
        updateQuotation(clone);
      }}
      isProductAddedToCart={true}
      className="numberField"
      updateValue={false}
      defaultValue={item.quantity}
      disabled={false}
      counterDisabled={isDisabled}
    />
  );
};
