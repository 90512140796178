import api from '../../../api';
import { ICustomer, Roles, User, UserType } from '../../api/models';
import { Dict } from '../../models';
import {
  createAction,
  createActionWithPayload,
  IAction,
  IActionWithPayload,
} from '../utils';
import { COMMON_RESET_DATA_ACTION, ResetActionType } from './common';

// action types
// login
const AUTH_LOGGED_IN_ACTION = 'AUTH/LOGGED_IN';
// profile
const AUTH_PROFILE_UPDATED_ACTION = 'AUTH/PROFILE_UPDATED_ACTION';
// logout
const AUTH_LOGOUT_ACTION = 'AUTH/LOGOUT';
// redirect
const AUTH_REDIRECT_SAVE_ACTION = 'AUTH/REDIRECT_SAVE';
const AUTH_REDIRECT_APPLY_ACTION = 'AUTH/REDIRECT_APPLY';

const AUTH_GET_ROLES_SUCCESS = 'AUTH/GET_ROLES_SUCCESS';

// Customer checkin
const AUTH_CHECK_IN_ACTION = 'AUTH/CHECK_IN';

export interface AuthState {
  userType?: UserType;
  userToken?: string;
  checkedInCustomer?: ICustomer;
  salesPersonToken?: string;
  redirectTo?: string;
  apiPermissions?: Dict;
  profile?: User;
  roles?: Roles;
}

type LoggedInActionType = IActionWithPayload<
  typeof AUTH_LOGGED_IN_ACTION,
  { token: string; userType: UserType; user: User }
>;

type CheckInActionType = IActionWithPayload<
  typeof AUTH_CHECK_IN_ACTION,
  ICustomer
>;

type RedirectSaveActionType = IActionWithPayload<
  typeof AUTH_REDIRECT_SAVE_ACTION,
  string
>;

type ProfileUpdatedActionType = IActionWithPayload<
  typeof AUTH_PROFILE_UPDATED_ACTION,
  User
>;

type LogoutActionType = IAction<typeof AUTH_LOGOUT_ACTION>;
type RedirectApplyActionType = IAction<typeof AUTH_REDIRECT_APPLY_ACTION>;

type Actions =
  | LoggedInActionType
  | RedirectSaveActionType
  | LogoutActionType
  | RedirectApplyActionType
  | ResetActionType
  | ProfileUpdatedActionType
  | CheckInActionType;

// initial state
const initialState: AuthState = {
  redirectTo: '/',
};

export const logoutAction = (): LogoutActionType => {
  return createAction(AUTH_LOGOUT_ACTION);
};

export const redirectApplyAction = (): RedirectApplyActionType => {
  return createAction(AUTH_REDIRECT_APPLY_ACTION);
};

export const redirectSaveAction = (to: string): RedirectSaveActionType => {
  return createActionWithPayload(AUTH_REDIRECT_SAVE_ACTION, to);
};

export const getRolesSuccessAction = (roles: Roles): any => {
  return createActionWithPayload(AUTH_GET_ROLES_SUCCESS, roles);
};

export const profileUpdatedAction = (
  profile: User
): ProfileUpdatedActionType => {
  return createActionWithPayload(AUTH_PROFILE_UPDATED_ACTION, profile);
};

export const loggedInAction = (data: {
  token: string;
  userType: UserType;
  user: User;
}): LoggedInActionType => {
  return createActionWithPayload(AUTH_LOGGED_IN_ACTION, data);
};

export const checkInAction = (customer: ICustomer): CheckInActionType => {
  return createActionWithPayload(AUTH_CHECK_IN_ACTION, customer);
};

// reducer
export const authUserReducer = (
  state: AuthState = initialState,
  action: any
): AuthState => {
  switch (action.type) {
    // login
    case AUTH_LOGGED_IN_ACTION:
      api.setUserToken(
        action.payload.userType === UserType.Customer ||
          action.payload.userType === UserType.User
          ? action.payload.token
          : undefined
      );
      api.setSalespersonToken(
        action.payload.userType === UserType.Salesperson
          ? action.payload.token
          : undefined
      );
      return {
        ...state,
        userToken:
          action.payload.userType === UserType.Customer ||
          action.payload.userType === UserType.User
            ? action.payload.token
            : undefined,
        salesPersonToken:
          action.payload.userType === UserType.Salesperson
            ? action.payload.token
            : undefined,
        userType: action.payload.userType,
        profile: action.payload.user,
      };
    case AUTH_CHECK_IN_ACTION:
      let userToken: string | undefined = action.payload.userToken;
      if (action.payload.userToken === state.userToken) {
        api.setUserToken();
        userToken = undefined;
      } else {
        api.setUserToken(action.payload.userToken);
      }
      return {
        ...state,
        userToken,
        checkedInCustomer: userToken ? action.payload : undefined,
      };
    case AUTH_GET_ROLES_SUCCESS:
      return { ...state, roles: action.payload };

    // logout
    case AUTH_LOGOUT_ACTION:
      api.setUserToken(undefined);
      api.setSalespersonToken(undefined);
      api.setUserType(undefined);
      return {
        ...state,
        userToken: undefined,
        checkedInCustomer: undefined,
        salesPersonToken: undefined,
        profile: undefined,
        userType: undefined,
      };
    // profile
    case AUTH_PROFILE_UPDATED_ACTION:
      return {
        ...state,
        profile: action.payload,
      };

    case COMMON_RESET_DATA_ACTION:
      return {
        ...initialState,
      };

    // redirect
    case AUTH_REDIRECT_SAVE_ACTION:
      return { ...state, redirectTo: action.payload };
    case AUTH_REDIRECT_APPLY_ACTION:
      return { ...state, redirectTo: undefined };
    default:
      return state;
  }
};
