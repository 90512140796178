import {
  AuthService,
  ModelsService,
  OrderService,
  UserService,
  StaffService,
  ReportSevice,
  SalesPersonReportSevice,
} from './services';

import { ApiServiceDataStore } from './data';
import { UserType } from './models';
import { PaymentService } from './services/payment-service';
import { CreditlineExtensionRequest } from './services/creditline-extension';
import { NotificationService } from './services/notification-service';
import { CustomerSevice } from './services/customer-service';
import { PaymentReportSevice } from './services/report-payment-service';
export class CoreApiClient {
  public model: ModelsService;
  protected store: ApiServiceDataStore;
  public auth: AuthService;
  public user: UserService;
  public staff: StaffService;
  public order: OrderService;
  public report: ReportSevice;
  public payment: PaymentService;
  public salesPerson: SalesPersonReportSevice;
  public customer: CustomerSevice;
  public paymentReport: PaymentReportSevice;
  public creditline: CreditlineExtensionRequest;
  public notification: NotificationService;

  constructor(apiDomain: string) {
    this.store = {
      apiDomain,
      userToken: undefined,
      salespersonToken: undefined,
      userType: undefined,
      brandID: undefined,
      branchID: undefined,
      languageID: 1,
    };
    this.model = new ModelsService(this.store);
    this.auth = new AuthService(this.store);
    this.user = new UserService(this.store);
    this.staff = new StaffService(this.store);
    this.payment = new PaymentService(this.store);
    this.report = new ReportSevice(this.store);
    this.customer = new CustomerSevice(this.store);
    this.salesPerson = new SalesPersonReportSevice(this.store);
    this.creditline = new CreditlineExtensionRequest(this.store);
    this.order = new OrderService(this.store);
    this.paymentReport = new PaymentReportSevice(this.store);
    this.notification = new NotificationService(this.store);
  }
  public setUserType(userType?: UserType) {
    this.store.userType = userType;
  }
  public getUserType() {
    return this.store.userType;
  }

  public setUserToken(token?: string) {
    this.store.userToken = token;
  }
  public getUserToken() {
    return this.store.userToken;
  }
  public setSalespersonToken(token?: string) {
    this.store.salespersonToken = token;
  }
  public getSalespersonToken() {
    return this.store.salespersonToken;
  }

  public setBrandID(brandID: string) {
    this.store.brandID = brandID;
  }
  public setBranchID(branchID: string) {
    this.store.branchID = branchID;
  }
  public setLanguageID(languageID?: number) {
    this.store.languageID = languageID ?? 1;
  }

  public getBrandID() {
    return this.store.brandID;
  }
  public getBranchID() {
    return this.store.branchID;
  }
  public getLanguageID() {
    return this.store.languageID;
  }
}
