import React from 'react';
import './Counter.scss';

interface CustomProps<T> {
  value: number;
  amountPerQuantity?: number;
  maxCount?: number;
  name?: string;
  placeholder?: string;
  label?: string;
  id?: string;
  gap?: 'sm' | 'md' | 'lg';
  size?: 'lg' | 'sm';
  withIcon?: string;
  smallIcon?: boolean;
  noBorder?: boolean;
  type?: 'text' | 'number';
  minValue?: string;
  toZero?: boolean;
  maxLength?: number;
  onChange: (value: number, onSuccess?: () => void) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  error?: string;
  disabled?: boolean;
  stickyStyles?: any;
  disableNumberScrolls?: boolean;
  showLoading?: boolean;
  className?: string;
  updateValue?: boolean;
  uom?: string;
  defaultValue: number;
  fromCart?: boolean;
  productMaxBuyLimit?: number;
  productMinBuyLimit?: number;
  productModel?: string;
  isProductAddedToCart?: boolean;
  windowWidth?: number;
  counterDisabled?: boolean;
  isLoading?: boolean;
}

interface CustomSate<T> {
  value: number | string;
  disabled?: boolean;
  maxReached?: boolean;
  isCurrent?: boolean;
  minReached?: boolean;
  prevValue: number | string;
}

class Counter<T extends string | number> extends React.Component<
  CustomProps<T>,
  CustomSate<T>
> {
  static defaultProps: Partial<CustomProps<string | number>> = {
    value: 1,
    defaultValue: 0,
    amountPerQuantity: 0,
    maxCount: 0,
    name: '',
    label: '',
    placeholder: '',
    type: 'text',
    gap: 'md',
    smallIcon: false,
    minValue: '0',
    onChange: () => {
      return;
    },
    error: '',
    size: 'sm',
    withIcon: '',
    toZero: false,
    noBorder: false,
    disabled: false,
    stickyStyles: {},
    disableNumberScrolls: false,
    className: '',
    updateValue: true,
    uom: '',
    fromCart: false,
    productMaxBuyLimit: 0,
    productMinBuyLimit: 0,
    productModel: '',
    isProductAddedToCart: false,
    windowWidth: 0,
    counterDisabled: false,
  };
  incrButton: any;
  decrButton: any;

  constructor(props: CustomProps<T>) {
    super(props);
    this.state = {
      value: props.value,
      disabled: Boolean(props.disabled),
      maxReached: false,
      isCurrent: false,
      prevValue: props.value,
    };

    this.incrementCounter = this.incrementCounter.bind(this);
    this.decrementCounter = this.decrementCounter.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.incrButton = null;
    this.decrButton = null;
  }

  componentDidMount() {
    this.incrButton.addEventListener('click', this.incrementCounter);
    this.decrButton.addEventListener('click', this.decrementCounter);
  }

  componentDidUpdate(prevProps: CustomProps<T>) {
    if (prevProps.value !== this.props.value) {
      this.updateDefault();
    }
  }

  updateDefault() {
    if (this.props.value !== undefined) {
      this.setState({
        value: this.props.value,
        maxReached: false,
      });
    }
  }

  onBlur() {
    const regx = /^[1-9][0-9]*$/;

    const value =
      this.state.value && this.state.value.toString().match(regx)
        ? Number(this.state.value)
        : 1;
    if (
      this.state.value.toString() === this.state.prevValue.toString() &&
      this.props.isProductAddedToCart
    ) {
      return;
    }

    this.setState({
      prevValue: value,
    });

    this.props.onChange(value);
  }

  onChange(event: { target: HTMLInputElement }) {
    const regx = /^[1-9][0-9]*$/;

    if (event.target.value.match(regx) || event.target.value === '') {
      const value = event.target.value ? Number(event.target.value) : '';

      this.setState({
        value,
      });
    } else {
      this.setState({
        value: '',
      });
    }
  }

  incrementCounter() {
    if (
      this.props.disabled ||
      this.props.counterDisabled ||
      (this.props.maxCount
        ? Number(this.state.value) >= this.props.maxCount
        : false)
    ) {
      return;
    }

    this.setState((prevState) => {
      return {
        value: this.props.updateValue
          ? prevState.value
            ? parseInt(prevState.value.toString(), 10) + 1
            : 1
          : prevState.value,
      };
    });

    const value = this.state.value ? Number(this.state.value) : 1;
    this.props.onChange(
      this.props.updateValue ? value : parseInt(value.toString(), 10) + 1
    );
  }

  decrementCounter() {
    if (this.props.counterDisabled) {
      return;
    }
    if (this.props.toZero === true) {
      if (Number(this.state.value) <= 0) {
        return;
      }
    } else {
      if (Number(this.state.value) - 1 <= 0) {
        return;
      }
    }

    this.setState((prevState) => {
      return {
        value: this.props.updateValue
          ? prevState.value
            ? parseInt(prevState.value.toString(), 10) - 1
            : 1
          : prevState.value,
      };
    });
    let value;
    if (this.props.toZero) {
      value = this.state.value ? Number(this.state.value) : 0;
    } else {
      value = this.state.value ? Number(this.state.value) : 1;
    }
    this.props.onChange(
      this.props.updateValue ? value : parseInt(value.toString(), 10) - 1
    );
  }
  formatNumber(num: any) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  render() {
    return (
      <div
        className={`${
          this.props.className ? this.props.className : 'numberField'
        }
        ${this.props.counterDisabled ? 'numberField-disabled' : ''}`}
      >
        <div className="numberField__inner">
          <span
            ref={(element) => (this.decrButton = element)}
            className={`numberField__minus`}
          ></span>
          <input
            type="text"
            disabled={
              this.props.disabled ||
              this.props.counterDisabled ||
              (this.props.maxCount
                ? Number(this.state.value) >= this.props.maxCount
                : false)
            }
            value={this.state.value}
            onChange={this.onChange}
            onBlur={this.onBlur}
            className="numberField__input"
          />
          <span
            ref={(element) => (this.incrButton = element)}
            className={`numberField__plus`}
          ></span>
          {this.props.isLoading && (
            <div className="numberField__loading">
              <img src={require('./loading.gif')} alt="loading..." />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Counter;
