import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../store/interfaces';
import api from '../../api';
import {
  quoteGetAction,
  quoteSetLoaderAction,
  quoteUpdateAction,
} from '../store';
import {
  IAddToQuoteModel,
  IBulkEstimatePreviewResponse,
  IQuotation,
  IQuotationDetails,
  IReplaceQuotationParam,
  IReplaceQuoteResponse,
  ISuggestPreviewResponse,
} from '../api/models';
import { toastr } from 'react-redux-toastr';
import { translate } from '../../utils/text-utils';
import { useHistory } from 'react-router-dom';
import { RouteKeys } from '../../containers/routes/route-keys';

export const useQuotes = () => {
  const quoteState = useSelector((state: State) => state.quote);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const [previewData, setPreviewData] = useState<ISuggestPreviewResponse>();

  // useEffect(() => {
  //   if (!quoteState.isQuoteLoaded && !quoteState.isQuoteLoading) {
  //     getQuotations();
  //   }
  // }, []);
  const getQuotations = async () => {
    setLoading(true);
    dispatch(quoteSetLoaderAction(true));
    const response = await api.model.getMyQuotes();
    setLoading(false);
    dispatch(quoteGetAction(response));
    dispatch(quoteSetLoaderAction(false));
  };

  const downlaodQuotations = async (param: any) => {
    try {
      setLoading(true);
      const response: any = await api.model.getQuoteRequest(param);
      if (response.response === 'Success') {
        if (response?.result?.exportUrl) {
          window.open(response?.result?.exportUrl);
          toastr.success(
            response.response,
            translate('toast.downloadCompleted')
          );
        }
      }
      // return response;
    } catch (err) {
      toastr.error('Error', translate('toast.unableToUpload'));
    } finally {
      setLoading(false);
    }
  };

  const addToQuote = async (data: IAddToQuoteModel) => {
    try {
      setLoading(true);
      const response = await api.model.addToQuote(data);
      if (response.response === 'Success') {
        dispatch(quoteUpdateAction(response?.result));
        toastr.success('Success', translate('toast.itemAddedToQuote'));
      } else {
        toastr.error('Error', 'Unable to add');
      }
    } catch (error) {
      toastr.error('Error', (error as any)?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const removeQuote = async (data: IQuotation) => {
    try {
      const response = await api.model.removeQuote(data);
      if (response) {
        toastr.success('Success', translate('toast.itemRemovedSuccessfully'));
        dispatch(quoteUpdateAction(response));
      } else {
        toastr.error('Error', 'Unable to remove item');
      }
    } catch (error) {
      toastr.error('Error', (error as any)?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const updateQuotation = async (item: IQuotation) => {
    const qdetails = quoteState?.quoteDetails;
    const index =
      qdetails?.items?.findIndex(
        (p) =>
          p.partNumber === item.partNumber &&
          p.quotationReqItemID === item.quotationReqItemID
      ) ?? -1;
    const clone = [...(qdetails?.items ?? [])];
    if (index > -1) {
      clone[index].isLoading = true;
      if (qdetails) {
        qdetails.items = clone;
        dispatch(quoteUpdateAction(qdetails));
      }
    }
    try {
      const response = await api.model.updateMyQuotationQuantity(item);
      dispatch(quoteUpdateAction(response));
      toastr.success('Success', translate('toast.quoteUpdatedSuccessfully'));
    } catch (error) {
      toastr.error('Error', (error as any)?.response?.data?.message);
      if (quoteState.quoteDetails) {
        dispatch(quoteUpdateAction(quoteState.quoteDetails));
      }
    }
  };

  const getQuotationDetails = async (
    requestID: string
  ): Promise<IQuotationDetails> => {
    try {
      setLoading(true);
      const response = await api.model.getQuotationDetails(requestID);
      if (response.response === 'Success') {
        return response.result;
      } else {
        toastr.error('Error', 'Unable to add');
        return undefined;
      }
    } catch (error) {
      toastr.error('Error', (error as any)?.response?.data?.message);
      return undefined;
    } finally {
      setLoading(false);
    }
  };

  const rejectQuoteRequest = async (requestId: string, reason?: string) => {
    try {
      setLoading(true);
      const response = await api.model.rejectQuotationRequest(
        requestId,
        reason
      );
      if (response?.response === 'Success') {
        toastr.success(response?.response, response?.errorMsg ?? '');
        history.push(`${RouteKeys.Quotation}/rejected`);
      }
      return response;
    } catch (err) {
      toastr.error('Error', (err as any)?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const confirmQuoteSuggest = async (
    param: IReplaceQuotationParam
  ): Promise<IReplaceQuoteResponse | undefined> => {
    try {
      setLoading(true);
      const response = await api.model.replaceQuotationItem(param);
      if (response?.response === 'Success') {
        toastr.success(response?.response, response?.errorMsg ?? '');
      } else {
        toastr.error(response?.response, response?.errorMsg ?? '');
        return undefined;
      }
      return response?.result;
    } catch (err) {
      toastr.error('Error', (err as any)?.response?.data?.message);
      return undefined;
    } finally {
      setLoading(false);
    }
  };

  const removeQuoteSuggest = async (
    param: IReplaceQuotationParam
  ): Promise<IReplaceQuoteResponse | undefined> => {
    try {
      setLoading(true);
      const response = await api.model.removeQuotationItem(param);
      if (response?.response === 'Success') {
        toastr.success(response?.response, response?.errorMsg ?? '');
      } else {
        toastr.error(response?.response, response?.errorMsg ?? '');
        return undefined;
      }
      return response?.result;
    } catch (err) {
      toastr.error('Error', (err as any)?.response?.data?.message);
      return undefined;
    } finally {
      setLoading(false);
    }
  };

  const previewSuggestRequest = async (quotationRequestID: string) => {
    try {
      setLoading(true);
      const response = await api.model.getPreviewQuotationSuggestList(
        quotationRequestID
      );
      if (response.response === 'Success') {
        setPreviewData(response.result);
      }
    } catch (error) {
      toastr.error('Error', 'Cannot submit request ');
    } finally {
      setLoading(false);
    }
  };

  const confirmSuggestRequest = async (quotationRequestID: string) => {
    try {
      setLoading(true);
      const response = await api.model.confirQuotationSuggestList(
        quotationRequestID
      );
      if (response.response === 'Success') {
        toastr.success(response?.response, response?.errorMsg ?? '');
        history.push(`${RouteKeys.Quotation}/estimated`);
      }
    } catch (error) {
      toastr.error('Error', 'Cannot submit request');
      setLoading(false);
    }
  };

  const quotationBulkEstimatePreview = async (
    file: File,
    action: 'add' | 'view'
  ): Promise<IBulkEstimatePreviewResponse[] | null> => {
    try {
      setLoading(true);

      const response = await api.model.quotationbulkEstimatePreview(
        file,
        action
      );
      return response;
    } catch (e) {
      toastr.error('Error', 'File upload failed');

      return null;
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    previewData,
    getQuotations,
    addToQuote,
    updateQuotation,
    removeQuote,
    getQuotationDetails,
    rejectQuoteRequest,
    confirmQuoteSuggest,
    removeQuoteSuggest,
    previewSuggestRequest,
    confirmSuggestRequest,
    setPreviewData,
    quotationBulkEstimatePreview,
    downlaodQuotations,
    quoteState,
    quotations: quoteState.quoteDetails?.items,
    loaded2: quoteState.isQuoteLoaded,
  };
};
