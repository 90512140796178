import React, { useEffect, useState } from 'react';
import FordLogoSm from '../../assets/images/brands/ford-sm.png';
import NissanLogoSm from '../../assets/images/brands/nissan-sm.png';
import HyundaiLogoSm from '../../assets/images/brands/hyundai-sm.png';
import CheryLogoSm from '../../assets/images/brands/chery-sm.png';
import { useDispatch, useSelector } from 'react-redux';
import {
  branchCartUpadateAction,
  branchQuotUpdateAction,
  branchSelectAction,
  brandSelectAction,
  State,
} from '../../core/store';
import { useBrandsAndBranches } from '../../core/hooks/useBrandsAndBranches';
import { useCart } from '../../core/hooks';
import { useQuotes } from '../../core/hooks/useQuotes';
import {
  Branch,
  CartDetails,
  IBrandBranchData,
  QuoteDetails,
} from '../../models/brand';
import Modal from '../../components/modal';
import { useHistory } from 'react-router-dom';
import { RouteKeys } from '../routes/route-keys';
import { translate } from '../../utils/text-utils';

interface CustomProps {
  isOpen: boolean;
  togglePopup: () => void;
}

const BrandSelection: React.FunctionComponent<CustomProps> = (
  props: CustomProps
) => {
  const history = useHistory();
  const { brandState } = useBrandsAndBranches();
  const { cart } = useCart();
  const { quoteState } = useQuotes();
  const path = window.location.pathname.split('/')[1];
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [brandDropdown, toggleBrandDropdown] = React.useState<boolean>(false);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [selectedBrand, setSelectedBrand] = useState<IBrandBranchData>();
  // const [list, setList] = React.useState<Branch[]>(
  //   brandState?.selectedBrand?.branches ?? []
  // );

  const list = searchTerm
    ? brandState?.selectedBrand?.branches?.filter((branch) =>
        branch.warehouseName?.match(new RegExp(searchTerm, 'si'))
      )
    : brandState?.selectedBrand?.branches;

  const dispatch = useDispatch();

  // useEffect(() => {
  //   const list = searchTerm
  //     ? brandState?.selectedBrand?.branches?.filter((branch) =>
  //         branch.warehouseName?.match(new RegExp(searchTerm, 'si'))
  //       )
  //     : brandState?.selectedBrand?.branches;
  //   setList(list ?? []);
  // }, [searchTerm, brandState?.selectedBrand?.branches]);

  useEffect(() => {
    if (cart?.cartID) {
      const cartDetails: CartDetails = {
        cartID: cart.cartID,
        orderQty: cart.orderQty,
        orderTotalAmount: `${cart.orderTotalAmount}`,
      };

      dispatch(
        branchCartUpadateAction({
          cartDetails,
          brandId: cart.brandID,
          branchId: cart.branch_id,
        })
      );
    }
  }, [cart?.cartID, cart?.orderQty]);

  useEffect(() => {
    const quotDetails: QuoteDetails = {
      quotationRequestID: '',
      totalQty: `${quoteState?.quoteDetails?.items?.length}`,
    };
    dispatch(
      branchQuotUpdateAction({
        quotDetails,
        brandId: quoteState?.quoteDetails?.brandID ?? '',
        branchId: quoteState?.quoteDetails?.branchID ?? '',
      })
    );
  }, [quoteState?.quoteDetails]);

  const confirmModal = () => {
    return (
      <Modal isOpen={showConfirmBox} contentClassName="alertPopup p-0">
        <div className="text-right p-3">
          <i
            className="icon-close text-gray-600 font-sm font-weight-bold cursor-pointer"
            onClick={() => {
              setShowConfirmBox(false);
            }}
          ></i>
        </div>
        <div className="px-md-5 px-sm-4 px-3 pt-4 pb-5">
          <div className="Verification__content">
            <h6 className="mb-4 pb-1">
              {translate('Header.selectingDiffernetBrand')}
            </h6>
          </div>

          <div className="row justify-content-end mb-4">
            <div className="col-auto">
              <button
                onClick={() => {
                  setShowConfirmBox(false);
                }}
                className="btn btn-outline-primary py-2 font-weight-medium font-md px-4"
              >
                {translate('Header.Cancel')}
              </button>
            </div>
            <div className="col-auto">
              <button
                onClick={() => {
                  setShowConfirmBox(false);
                  if (selectedBrand) {
                    dispatch(brandSelectAction(selectedBrand));
                    setSelectedBrand(undefined);
                    history.push(RouteKeys.Home);
                  }
                }}
                className="btn btn-primary py-2 font-weight-medium font-md px-4"
              >
                {translate('Header.confirmProceed')}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  };
  return (
    <>
      <div
        className={`bg-white position-fixed top-0 brandSelection-popup w-100 h-100 p-4 d-flex flex-column ${
          props.isOpen ? 'active' : ''
        }`}
      >
        <i
          className="icon-cancel d-inline-block text-gray-500 mb-4 cursor-pointer"
          onClick={() => props.togglePopup()}
        ></i>
        <div className="brand-select mb-3">
          <label
            htmlFor="Select Brand"
            className="font-md font-weight-bold mb-1"
          >
            {translate('Header.selectBrand')}
          </label>
          <div className="position-relative">
            <div
              className="d-flex align-items-center font-md font-weight-bold shadow-sm cursor-pointer brand-selected"
              onClick={() => toggleBrandDropdown(!brandDropdown)}
            >
              {brandState?.selectedBrand?.brandName ?? 'Select'}
              <div className="ml-auto d-flex align-items-center">
                {brandState?.selectedBrand?.brandIcon && (
                  <img
                    src={brandState?.selectedBrand?.brandIcon}
                    className="img-fluid"
                    alt={brandState?.selectedBrand?.brandName}
                    style={{ maxHeight: '24px' }}
                  />
                )}
                <i className="icon-arrow-down font-xxxs ml-md-4 pl-md-2"></i>
              </div>
            </div>
            {brandDropdown && (
              <div className="position-absolute w-100 left-0 brand-options shadow rounded bg-white">
                {brandState.brandAdBranch?.map((brand, index) => {
                  return (
                    <div
                      key={`brand_${brand.brandID}_${index}`}
                      className="d-flex align-items-center font-md font-weight-bold cursor-pointer border-bottom brand-option"
                      onClick={() => {
                        // setBrandSelect({
                        //   selected: 'Ford',
                        //   selectedImage: FordLogoSm,
                        // });
                        if (!['parts', 'search', 'categories'].includes(path)) {
                          dispatch(brandSelectAction(brand));
                          toggleBrandDropdown(!brandDropdown);
                        } else {
                          if (
                            brandState.selectedBrand?.brandID !== brand.brandID
                          ) {
                            setSelectedBrand(brand);
                            setShowConfirmBox(true);
                            props.togglePopup();
                          } else {
                            props.togglePopup();
                          }
                        }
                      }}
                    >
                      {brand.brandName ?? ''}
                      <img
                        src={brand?.brandIcon}
                        className="ml-auto"
                        alt={brand.brandName ?? ''}
                        style={{ maxHeight: '24px' }}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        <div className="mb-4 brand-select-info px-3 py-2 font-sm d-flex rounded align-items-center line-height-normal">
          <i className="icon-branch-info h4 mb-0 mr-2"></i>
          {translate('Header.cartWillBeDifferent')}
        </div>
        <div className="bg-gray-100 p-3 h-100 d-flex flex-column">
          <div className="input-group mb-4">
            <button
              className="icon-search form-control border-right-0"
              style={{ height: '46px', maxWidth: '46px' }}
            ></button>
            <input
              type="text"
              className="form-control font-md border-left-0 pl-0"
              placeholder={translate('Home.searchBranch')}
              style={{ height: '46px' }}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="font-md font-weight-bold mb-2">
            {brandState?.selectedBrand?.brandName ?? ''}{' '}
            {translate('Header.Branches')}
          </div>
          <div className="branch-list overflow-auto">
            {list?.map((branch, index) => {
              return (
                <div
                  className={`bg-white rounded py-2 pl-3 pr-2 d-flex flex-wrap mb-2 cursor-pointer branches ${
                    brandState?.selectedBranch?.warehouseID ===
                    branch?.warehouseID
                      ? 'active'
                      : ''
                  }`}
                  key={`branch_${branch?.warehouseID}_${index}`}
                  onClick={() => {
                    dispatch(branchSelectAction(branch));
                    props.togglePopup();
                  }}
                >
                  <div className="col-sm-6 col-12 py-1 pl-1">
                    <p className="font-xs text-gray-600 text-uppercase branch-key mb-0">
                      {translate('Header.FPDI')}
                    </p>
                    <p className="font-md text-gray-900 branch-title mb-0">
                      {branch?.warehouseName}
                    </p>
                  </div>
                  <div className="col-sm-6 col-12 py-1 pr-1 text-right">
                    <p className="font-sm mb-0">
                      <span className="text-gray-600">
                        {translate('Header.InCart')}
                      </span>{' '}
                      {branch?.cartDetails?.orderQty ?? 0}{' '}
                      {translate('Header.Items')} ({translate('Header.SAR')}{' '}
                      {Number(
                        branch?.cartDetails?.orderTotalAmount ?? 0
                      ).toFixed(2)}
                      )
                    </p>
                    <p className="font-sm mb-0">
                      <span className="text-gray-600">
                        {translate('Header.InQuote')}
                      </span>{' '}
                      {branch?.quotDetails?.totalQty?.replace(
                        'undefined',
                        '0'
                      ) ?? 0}{' '}
                      {translate('Header.Items')}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {props.isOpen && <div className="modal-backdrop fade show"></div>}
      {confirmModal()}
    </>
  );
};

export default BrandSelection;
