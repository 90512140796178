import React from 'react';
import './input.scss';

interface CustomProps<T> {
  default?: T;
  name?: string;
  placeholder?: string;
  label?: string;
  id?: string;
  gap?: 'no' | 'sm' | 'md' | 'lg';
  size?: 'lg' | 'sm' | 'md';
  withIcon?: string;
  smallIcon?: boolean;
  noBorder?: boolean;
  type?: 'text' | 'number';
  minValue?: string;
  pattern?: string;
  maxLength?: number;
  onChange: (value: string | number) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  error?: string;
  disabled?: boolean;
  stickyStyles?: any;
  disableNumberScrolls?: boolean;
  showLoading?: boolean;
  autoComplete?: string;
  autofocus?: boolean;
}

interface CustomSate<T> {
  value?: string | number;
}

class TextInput<T extends string | number> extends React.Component<
  CustomProps<T>,
  CustomSate<T>
> {
  static defaultProps: Partial<CustomProps<string | number>> = {
    default: '',
    name: '',
    label: '',
    placeholder: '',
    type: 'text',
    gap: 'md',
    smallIcon: false,
    minValue: '0',
    onChange: () => {
      return;
    },
    error: '',
    size: 'sm',
    withIcon: '',
    noBorder: false,
    disabled: false,
    stickyStyles: {},
    disableNumberScrolls: false,
    autoComplete: 'on',
    autofocus: false,
  };

  constructor(props: CustomProps<T>) {
    super(props);
    this.state = {
      value: props.default,
    };
    this.handleChange = this.handleChange.bind(this);
  }
  


  componentDidUpdate(prevProps: CustomProps<T>) {
    if (this.props.default && prevProps.default !== this.props.default) {
      this.updateDefault();
    }
  }

  updateDefault() {
    if (this.props.default !== undefined) {
      this.setState({ value: this.props.default });
      this.props.onChange(this.props.default);
    }
  }

  handleChange(e: { target: HTMLInputElement }) {
    if (this.props?.pattern) {
      if (RegExp(this.props.pattern).test(e.target.value)) {
        let value: string | number = e.target.value;

        if (this.props.type === 'number' && e.target.value) {
          value = Number(value);
        }

        this.setState({ value });
        this.props.onChange(value);
      }
    } else {
      let value: string | number = e.target.value;

      if (this.props.type === 'number' && e.target.value) {
        value = Number(value);
      }

      this.setState({ value });
      this.props.onChange(value);
    }
  }

  render() {
    const {
      size,
      noBorder,
      withIcon,
      disableNumberScrolls,
      name,
      label,
      placeholder,
      gap,
      stickyStyles,
      error,
      smallIcon,
      disabled,
      minValue,
      pattern,
      maxLength,
      type,
      showLoading,
      onFocus,
      onBlur,
      id,
      autoComplete,
    } = this.props;
    const extraClassesInput = [];
    if (size === 'lg') extraClassesInput.push('textField__input--lg');
    if (size === 'sm') extraClassesInput.push('textField__input--sm');
    if (noBorder) extraClassesInput.push('textField__field--noborder');
    if (withIcon || showLoading) {
      extraClassesInput.push('textField__field--icon');
    }
    if (disableNumberScrolls) {
      extraClassesInput.push('disable-number-scrolls');
    }
    if (disabled) {
      extraClassesInput.push('textField__field--disabled');
    }
    const extraClasses: string = extraClassesInput.join(' ');
    const inputIdCandidate = id || name || label || placeholder || '';
    const inputId = inputIdCandidate.toLowerCase().split(' ').join('-');
    return (
      <div
        className={`textField textField--margin-${gap}`}
        style={stickyStyles}
      >
        {label && (
          <label className="textField__label" htmlFor={inputId}>
            {label}
          </label>
        )}
        {withIcon && (
          <i className={`icon-${withIcon} ${smallIcon ? 'icon-sm' : ''}`} />
        )}
        <input
          id={inputId}
          className={`textField__input ${extraClasses}`}
          type={type}
          placeholder={placeholder}
          onChange={this.handleChange}
          value={this.state.value}
          min={minValue}
          pattern={pattern}
          maxLength={maxLength}
          disabled={disabled}
          onWheel={(e) => this.props.disableNumberScrolls && e.preventDefault()}
          onScroll={(e) =>
            this.props.disableNumberScrolls && e.preventDefault()
          }
          onKeyDown={(e) =>
            this.props.disableNumberScrolls &&
            (e.keyCode === 38 || e.keyCode === 40) &&
            e.preventDefault()
          }
          onFocus={onFocus}
          onBlur={onBlur}
          autoComplete={autoComplete}
          autoFocus={this.props.autofocus}
        />

        {showLoading && (
          <div className="textFieldLoading">
            <span className="textFieldLoading__dot textFieldLoading__dotF"></span>
            <span className="textFieldLoading__dot textFieldLoading__dotM"></span>
            <span className="textFieldLoading__dot textFieldLoading__dotL"></span>
          </div>
        )}
        {error && <span className="textField__validation">{error}</span>}
      </div>
    );
  }
}

export default TextInput;
